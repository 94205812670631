import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import es from 'date-fns/locale/es';
import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';
import pt from 'date-fns/locale/pt';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { setExactSearch } from 'store/actions';

import PersonesTab from './PersonesTab';
import MessagesTab from './MessagesTab';
import DialogsTab from './DialogsTab';

import ModalWrapper from '../layout';
import { TextField } from '../../../components/Fields';
import { Spinner } from '../../../components';

import {
  setSearchModeState,
  setCurrentSearchDialog,
  setCurrentMessageId,
  fetchSearchMessages,
  fetchSearchPersones,
  fetchSearchGroupDialogs,
} from '../../../store/actions/searchActions';

import {
  getChatMessages,
  getChatDialog,
  openDialog,
} from '../../../store/actions/chatActions';

import { DEFAULT_DIALOG_MESSAGES_PAGE_SIZE } from '../../../config';

registerLocale('es', es);
registerLocale('ru', ru);
registerLocale('de', de);
registerLocale('it', it);
registerLocale('pt', pt);

const Checkbox = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
`;

const Wrapper = styled.div`
  .search-tabs {
    margin-top: 25px;
    display: flex;
    font-size: 15px;
    > div {
      cursor: pointer;
      margin-right: 15px;
      &.active {
        border-bottom: 2px solid #000;
      }
      span {
        font-weight: 600;
        margin-left: 5px;
      }
    }
  }

  .search-main {
    margin-top: 25px;
    overflow: auto;
    display: flex;
    min-height: 350px;
  }

  .search-loading {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: inherit;
  }

  .not-found-block {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .search-filters {
    margin-left: 50px;
    .search-filters-title {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 25px;
    }
    > div:not(.search-filters-title) {
      position: relative;
      font-size: 15px;
      color: #26282d;
      border-radius: 3px;
      border: solid 1px #eeeff2;
      background-color: #f6f8fc;
      background-repeat: no-repeat;
      background-position: 12px 10px;
      padding: 9px 18px 9px 35px;
      cursor: pointer;
      .clear-filter {
        position: absolute;
        top: 11px;
        right: 12px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #727887;
        background-image: url(/img/small-close.svg);
        background-position: center center;
        background-repeat: no-repeat;
        cursor: pointer;
      }
      &.checkbox-filter {
        user-select: none;
        background-image: none !important;
        padding-left: 10px;
        margin-top: 10px;
        display: flex;
        gap: 5px;
        font-size: 14px;
      }
      &.date-filter {
        background-image: url(/img/calendar.svg);
        input {
          cursor: pointer;
          margin-left: 0;
          background-color: transparent;
          color: #26282d;
          border: none;
          font-size: 14px;
          outline: none !important;
          ::placeholder,
          ::-webkit-input-placeholder {
            color: #26282d;
          }
          :-ms-input-placeholder {
            color: #26282d;
          }
        }
        .react-datepicker-popper {
          transform: translate3d(-31px, 30px, 0px) !important;
          box-shadow: 0 18px 42px 0 rgba(114, 120, 135, 0.1),
            0 4px 16px 0 rgba(0, 0, 0, 0.15);
        }
        .react-datepicker-popper[data-placement^='bottom']
          .react-datepicker__triangle,
        .react-datepicker-popper[data-placement^='bottom']
          .react-datepicker__triangle::before {
          border-bottom-color: #fff;
        }
        .react-datepicker__navigation--previous {
          border-right-color: #484c56;
        }
        .react-datepicker__navigation--next {
          border-left-color: #484c56;
        }
        .react-datepicker__header {
          background-color: #fff;
          border-bottom: none;
        }
        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
          font-size: 15px;
          font-weight: 500;
          color: #484c56;
        }
        .react-datepicker__day-names {
          background-color: #f6f8fc;
          margin-top: 10px;
        }
        .react-datepicker__day-name {
          color: #7d8392;
          text-transform: uppercase;
          font-size: 12px;
        }
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__day--selected {
          border-radius: 50%;
          background-color: #006ae4 !important;
        }
        .react-datepicker__day:hover {
          border-radius: 50%;
        }
        .react-datepicker {
          border: none;
        }
      }
    }
  }
`;

const TABS = [
  { key: 'persone', title: 'Персоны' },
  { key: 'message', title: 'Сообщения' },
  { key: 'dialog', title: 'Групповые чаты' },
];

const SearchModal = (props) => {
  const { i18n } = useTranslation();
  const { data, onClose } = props;
  const { query } = data;
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState(query || '');
  const [currentTab, setCurrentTab] = useState(TABS[0].key);
  const [currentDataMessages, setCurrentDataMessages] = useState(null);
  const [currentDataPersones, setCurrentDataPersones] = useState(null);
  const [currentDataDialogs, setCurrentDataDialogs] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [findMessageProcessing, setFindMessageProcessing] = useState(false);
  const [findDialogProcessing, setFindDialogProcessing] = useState(false);
  const [page, setPage] = useState(1); 
  const [loadingMin, setLoadingMin] = useState(false)

 


  const dispatch = useDispatch();
  const exactSearch = useSelector((state) => state.settings.exactSearch);
  const dialogs = useSelector((state) => state.chat.dialogs);
  const searchState = useSelector((state) => state.search);
  const dialogHistory = searchState.currentDialog
    ? searchState.currentDialog.history
    : [];

  const routerHistory = useHistory();
  const { search } = useLocation();

  const fetchNextPage = async () => {
    
    const totalPages = Math.ceil(currentDataPersones.pagination.count / currentDataPersones.pagination.size);
 
    if (loadingMin || page >= totalPages) {
      return;
    }

    setLoadingMin(true);
    const searchData = {
      text: searchValue,
    };

    const promiseResult = await dispatch(fetchSearchPersones(searchData, page + 1)); 
    const personesResult = promiseResult;

    if (personesResult && personesResult.rows && personesResult.rows.length) {
      const newData = {
        result: [...currentDataPersones.result, ...personesResult.rows],
        pagination: personesResult.pagination,
      };

      setCurrentDataPersones(newData);
      setPage(page + 1);
    }
    setLoadingMin(false);
  };

  const getSearch = async () => {
    setLoading(true);
    const searchData = {
      text: searchValue,
    };
    if (startDate) {
      searchData.dateFrom = moment(startDate).format('YYYY-MM-DD hh:mm:ss');
    }

    if (endDate) {
      searchData.dateTo = moment(endDate).format('YYYY-MM-DD hh:mm:ss');
    }

    const promiseResult = await Promise.all([
      dispatch(fetchSearchPersones(searchData)),
      //dispatch(fetchSearchMessages(searchData)),
      dispatch(fetchSearchGroupDialogs(searchData)),
    ]);

    const personesResult = promiseResult[0];
    if (personesResult && personesResult.rows && personesResult.rows.length) {
      const newData = {
        result: personesResult.rows,
        pagination: personesResult.pagination,
      };
    
     setCurrentDataPersones(newData);
   
    } else {
      setCurrentDataPersones(null);
    }

    const dialogsResult = promiseResult[2];
    if (dialogsResult && dialogsResult.rows && dialogsResult.rows.length) {
      const newData = {
        result: dialogsResult.rows,
        pagination: dialogsResult.pagination,
      };
      setCurrentDataDialogs(newData);
    } else {
      setCurrentDataDialogs(null);
    }

    const searchResult = promiseResult[1];
    if (searchResult && searchResult.rows && searchResult.rows.length) {
      const newData = {
        result: searchResult.rows.sort((a, b) => {
          if (a.date.created > b.date.created) return -1;
          if (a.date.created < b.date.created) return 1;
          return 0;
        }),
        pagination: searchResult.pagination,
      };
      setCurrentDataMessages(newData);
    } else {
      setCurrentDataMessages(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    routerHistory.push(`/chat${search}`);
  }, []);

  useEffect(() => {
    const searchInputTimeout = setTimeout(() => {
      if(searchValue) {
        getSearch();
      } else {
        setCurrentDataPersones(null);
        setCurrentDataMessages(null);
      }
    }, 200)
    return () => clearTimeout(searchInputTimeout);
  }, [searchValue, startDate, endDate, exactSearch])

  const openDialogAndCloseModal = (dialogUuid) => {
    dispatch(openDialog(dialogUuid));
    onClose();
  };

  const operatingCurrentDialog = (currentDialog, currentMessageId) => {
    dispatch(setSearchModeState(true));
    dispatch(setCurrentSearchDialog(currentDialog));

    if (currentMessageId) {
      const currentMessage = currentDialog.history.find(
        (m) => m.uuid === currentMessageId,
      );
      if (currentMessage) {
        openDialogAndCloseModal(currentDialog.uuid);
      } else {
        setFindMessageProcessing(currentMessageId);
      }
    } else {
      openDialogAndCloseModal(currentDialog.uuid);
    }
  };

  useEffect(() => {
    if (findDialogProcessing) {
      const dialogUuid = findDialogProcessing;
      const findCurrentDialog = dialogs.find((d) => d.uuid === dialogUuid);

      if (findCurrentDialog) {
        operatingCurrentDialog(findCurrentDialog, searchState.currentMessageId);
        setFindDialogProcessing(false);
        return;
      }

      getChatDialog({
        uuidDialog: dialogUuid,
        sort: 'desc',
        historySize: DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
      })(dispatch);
    }
  }, [findDialogProcessing, JSON.stringify(dialogs)]);

  useEffect(() => {
    if (findMessageProcessing && searchState.currentDialog) {
      const actualMessage = findMessageProcessing;
      const dialog = searchState.currentDialog;
      const findCurrentMessage = dialog.history.find(
        (m) => m.uuid === actualMessage,
      );

      if (findCurrentMessage) {
        openDialogAndCloseModal(dialog.uuid);
        return;
      }

      const reqObj = {
        uuidDialog: dialog.uuid,
        uuidPersone: dialog.ids.uuidPersone,
        uuidProvider: dialog.ids.uuidProvider,
      };

      const newPage = dialog.historyPagination.page + 1;
      if (
        newPage * DEFAULT_DIALOG_MESSAGES_PAGE_SIZE -
          dialog.historyPagination.count >
        DEFAULT_DIALOG_MESSAGES_PAGE_SIZE
      ) {
        return; // TODO !!!
      }
      reqObj.page = newPage;
      dispatch(getChatMessages(reqObj));
    }
  }, [findMessageProcessing, JSON.stringify(dialogHistory)]);

  useEffect(() => {
    setStartDate(null);
  }, [currentTab]);

  const searchNode = useCallback((node) => {
    if (node !== null) {
      node.focus();
    }
  }, []);

  const handleSetDate = (date, type) => {
    if (type === 'start') {
      setStartDate(date);
    } else {
      setEndDate(date);
    }
  };

  const handleClearDate = (type) => {
    if (type === 'start') {
      setStartDate(null);
    } else {
      setEndDate(null);
    }
  };

  const handleSelectTab = (tabKey) => {
    setCurrentTab(tabKey);
  };


  const handleOpenPersone = useCallback((persone) => {
    const dialogUuid = persone.dialog.uuid;
    setLoading(true);
    dispatch(setCurrentMessageId(null)); // TODO Remove

    const currentDialog = dialogs.find((d) => d.uuid === dialogUuid);
    if (currentDialog) {
      openDialogAndCloseModal(currentDialog.uuid);
    } else {
      setFindDialogProcessing(dialogUuid);
    }
  }, [dialogs, dispatch, openDialogAndCloseModal]);

  const handleOpenMessage = (message) => {
    dispatch(setCurrentMessageId(message.uuid));
    const currentDialog = dialogs.find(
      (d) => d.uuid === message.ids.uuidDialog,
    );
    if (currentDialog) {
      operatingCurrentDialog(currentDialog, message.uuid);
    } else {
      setFindDialogProcessing(message.ids.uuidDialog);
    }
  };

  const handleOpenDialog = (dialog) => {
    const currentDialog = dialogs.find((d) => d.uuid === dialog.uuid);
    if (currentDialog) {
      openDialogAndCloseModal(currentDialog.uuid);
    } else {
      setFindDialogProcessing(dialog.uuid);
    }
  };

  const handleSwitchExactSearch = () => {
    dispatch(setExactSearch(!exactSearch));
  };

  const messages = currentDataMessages && currentDataMessages.result;
  const memoizedPersones = useMemo(() => currentDataPersones?.result || [], [currentDataPersones]);
  const searchedDialogs = currentDataDialogs && currentDataDialogs.result;

  return (
    <ModalWrapper
      onClose={() => onClose()}
      wrapperStyle={{ paddingTop: '25px' }}
      modalStyle={{ width: '85%', maxWidth: '85%' }}
    >
     <Wrapper>
        <TextField
          id="modalSearchInput"
          label="Поиск"
          ref={searchNode}
          value={searchValue}
          wrapperStyle={{ marginTop: 0 }}
          labelStyle={{ color: '#000' }}
          onChange={setSearchValue}
        />
        <div className="search-tabs">
          {TABS.map((t) => (
            <div
              key={t.key}
              className={`${t.key === currentTab ? 'active' : ''}`}
              onClick={() => handleSelectTab(t.key)}
            >
              {`${t.title}`}

              {t.key === 'persone' ? (
                <span>
                  {currentDataPersones && currentDataPersones.pagination.count}
                </span>
              ) : null}

              {t.key === 'message' ? (
                <span>
                  {currentDataMessages && currentDataMessages.pagination.count}
                </span>
              ) : null}

              {t.key === 'dialog' ? (
                <span>
                  {currentDataDialogs && currentDataDialogs.pagination.count}
                </span>
              ) : null}
            </div>
          ))}
        </div>
        <div className="search-main">
          {!loading ? (
            <div style={{ flexGrow: 1 }}>
              {currentTab === 'persone' ? (
                memoizedPersones.length > 0 ? (
                  <PersonesTab
                    persones={memoizedPersones}
                    handleOpenPersone={handleOpenPersone}
                    fetchNextPage={fetchNextPage}
                    loadingMin={loadingMin}
                  />
                ) : (
                  <div className="not-found-block">
                    <div>Ничего не найдено</div>
                  </div>
                )
              ) : null}
              {currentTab === 'message' ? (
                messages ? (
                  <MessagesTab
                    messages={messages}
                    handleOpenMessage={handleOpenMessage}
                  />
                ) : (
                  <div className="not-found-block">
                    <div>Ничего не найдено</div>
                  </div>
                )
              ) : null}
              {currentTab === 'dialog' ? (
                searchedDialogs ? (
                  <DialogsTab
                    dialogs={searchedDialogs}
                    handleOpenDialog={handleOpenDialog}
                  />
                ) : (
                  <div className="not-found-block">
                    <div>Ничего не найдено</div>
                  </div>
                )
              ) : null}
            </div>
          ) : (
            <div className="search-loading" style={{ flexGrow: 1 }}>
              <Spinner show />
            </div>
          )}
          <div className="search-filters">
            <div className="search-filters-title">Фильтры</div>
            <div className="date-filter">
              <DatePicker
                className="date-filter"
                locale={i18n.language}
                placeholderText="от даты"
                maxDate={new Date()}
                dateFormat="dd.MM.yyyy"
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                selectsStart
                onChange={(date) => handleSetDate(date, 'start')}
              />
              {startDate ? (
                <span
                  className="clear-filter"
                  onClick={() => handleClearDate('start')}
                />
              ) : null}
            </div>
            <div className="date-filter" style={{ marginTop: '10px' }}>
              <DatePicker
                className="date-filter"
                locale={i18n.language}
                placeholderText="до даты"
                dateFormat="dd.MM.yyyy"
                minDate={startDate}
                maxDate={new Date()}
                selected={endDate}
                startDate={startDate}
                endDate={endDate}
                selectsEnd
                onChange={(date) => handleSetDate(date, 'end')}
              />
              {startDate ? (
                <span
                  className="clear-filter"
                  onClick={() => handleClearDate('end')}
                />
              ) : null}
            </div>
            <div className="checkbox-filter" onClick={handleSwitchExactSearch}>
              <Checkbox>
                <input type="checkbox" checked={exactSearch} />
              </Checkbox>
              точное совпадение
            </div>
          </div>
        </div>
      </Wrapper> 
    </ModalWrapper>
  );
};

SearchModal.propTypes = {
  data: PropTypes.shape({
    query: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SearchModal;
